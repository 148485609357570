import { ArrowBackIcon } from "@chakra-ui/icons";
import { Heading, Link, Text } from "@chakra-ui/react";
import { NextPage } from "next";

const Custom404: NextPage = () => {
  return (
    <>
      <Heading size="2xl">Sorry!</Heading>
      <Text fontSize="2xl">We can&apos;t find that page 😞.</Text>
      <Link fontSize="2xl" href="/budget/dashboard">
        <ArrowBackIcon /> Back to home.
      </Link>
    </>
  );
};

export default Custom404;
